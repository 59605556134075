<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="j_comm_comments"
    style="z-index: 999;"
    :id="localId"
    :opacity="0"
  >
    <div class="j_comm_comments__content_wrapper" :class="classWrapper">
      <div class="lnb">
      </div>
      <div class="content">
        <div class="action_button_wrapper">
          <a v-if="addable" v-ripple class="btn-add" title="add new comment" @click="onWrite(0)">
            <i class="mdi mdi-plus-circle-outline"></i>
          </a>
          <a v-if="excelExpotable" v-ripple class="btn-export" title="export to excel" @click="excelDownload">
            <img :src="require('@/assets/img/icon/microsoft-excel.png')" width="20" height="20" />
          </a>
        </div>
        <a v-ripple class="btn-cancel" title="Cancel & Close" @click="onClose">
          <i class="mdi mdi-close"></i>
        </a>
        <!-- <a v-ripple class="sub-menu---activator" title="sub menu" @click="subMenuOn=!subMenuOn">
          <i class="mdi mdi-dots-vertical"></i>
        </a>
        <div v-if="subMenuOn" class="sub-menu">
          <div 
            v-for="option in subMenuOptions" 
            class="sub-menu-item" 
            :key="option.index"
            @click="onSubMenuClicked(option.mode)"
          >
            <span class="sub-menu-label">{{ option.label }}</span>
          </div>
        </div> -->

        <div>
          <div class="tab-header">
            <span class="tab-title">Menu Revision Notice</span>
            <span class="tab-sub-title">{{comments_title}}</span>
          </div>
          <div class="comment_wrapper">

            <div v-for="comment in comments" :class="`comment_item depth_${comment.depth}`" :key="comment.idx">
              <!-- <div class="avatar-wrapper">
                <v-avatar>
                  <img :src="require('@/assets/img/avatar.png')" />
                </v-avatar>
              </div> -->
              <div class="member-info">
                <div v-if="senderItemBlock">
                  <div class="member-name">
                    <span class="name">{{ comment.ext1 }}</span>
                    <span v-if="!comment.editor" class="updated">
                      <i class="mdi mdi-clock-outline"></i>
                      <span class="time">{{ comment.ext2 }}</span>
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div class="member-name">
                    <span class="name">{{ comment.userName }}</span>
                    <span v-if="!comment.editor" class="updated">
                      <span class="date">{{ comment.date }}</span>
                      <i class="mdi mdi-clock-outline"></i>
                      <span class="time">{{ comment.time }}</span>
                    </span>
                  </div>
                  <div class="member-position">
                    <span class="position">{{ comment.office }}</span> /
                    <span class="position">{{ comment.position }}</span> /
                    <span class="department">{{ comment.dept }}</span>
                  </div>
                </div>
                
                <div v-if="comment.editor && sender2input">
                  <j-editor 
                    style="margin-top: 2.5rem;"
                    :class="`editor depth_${comment.depth}`" 
                    :comment="comment"
                    :progress="progress" 
                    :allowNoComment="true"
                    @save="d => onSave(d, comment)"
                    @cancel="onCancel" 
                  />
                  <div class="ext_data_row" v-if="manualExtData">
                    <div class="ext_data_field">
                      <span class="extra_label">Package</span>
                      <input v-model="comment['ext1']" class="extra_data" type="text">
                    </div>
                  </div>
                  <!-- <div v-for="(field, i) in sender2input" class="ext_data_row" :key="i">
                    <div v-if="field[0]" class="ext_data_field">
                      <span class="extra_label">{{ field[0].label }}</span>
                      <input v-model="comment[field[0].colName]" class="extra_data" type="text">
                    </div>
                    <div v-if="field[1]" class="ext_data_field">
                      <span class="extra_label">{{ field[1].label }}</span>
                      <input v-model="comment[field[1].colName]" class="extra_data" type="text">
                    </div>
                    <div v-if="field[2]" class="ext_data_field">
                      <span class="extra_label">{{ field[2].label }}</span>
                      <input v-model="comment[field[2].colName]" class="extra_data" type="text">
                    </div>
                    <div v-if="field[3]" class="ext_data_field">
                      <span class="extra_label">{{ field[3].label }}</span>
                      <input v-model="comment[field[3].colName]" class="extra_data" type="text">
                    </div>
                    <div v-if="field[4]" class="ext_data_field">
                      <span class="extra_label">{{ field[4].label }}</span>
                      <input v-model="comment[field[4].colName]" class="extra_data" type="text">
                    </div>
                  </div> -->
                </div>

                <div v-else>
                  <div class="button_replay_wrapper">
                    <!-- <a v-ripple v-if="comment.userId==account.id" class="btn-edit" title="edit" @click="onEdit(comment.idx)"> -->
                    <a v-ripple v-if="editable(comment)" class="btn-edit" title="edit" @click="onEdit(comment.idx)">
                      <i class="mdi mdi-pencil"></i>
                    </a>
                    <a v-if="removable(comment)" v-ripple class="btn-remove" title="delete" @click="onDelete(comment)">
                      <i class="mdi mdi-trash-can"></i>
                    </a>
                    <a v-if="comment.depth==0" v-ripple class="btn-reply" title="reply" @click="onWrite(comment.idx)">
                      <i class="mdi mdi-reply"></i>
                    </a>
                  </div>
                  <div v-html="comment.comment" class="comment"></div>
                  <div v-if="comment.files.length > 0" class="attachement">
                    <div v-if="fileDocs(comment.files).length>0" class="file_names">
                      <i class="mdi mdi-attachment"></i>
                      <a v-for="doc in fileDocs(comment.files)" class="names" :key="doc.idx" @click="onDownload(doc.idx)">{{ doc.name }}</a>
                    </div>
                    <div v-if="fileImages(comment.files).length>0" class="thumbs">
                      <div v-for="image in fileImages(comment.files)" class="thumb" :key="image.idx">
                        <a @click="onDownload(image.idx)">
                          <img :src="image.dataUrl" :title="image.name" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </j-overlay>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import * as d3 from 'd3'
import __C from '@/primitives/_constant_'
import { CommunicationService, FileService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Download from '@/mixins/download.mixin'
import Loading from '@/mixins/loading.mixin'
import Url from '@/services/api/request.url'

export default {
  name: 'j-modal-slide-component---comments',
  mixins: [
    Download,
    Loading,
    SafeIdMixin
  ],
  props: {
    value: null,
  },
  data: () => ({
    communicationService: null,
    fileService: null,

    classWrapper: 'closed',
    comments: [],

    files: [],
    totaluploadprogress: 0,
    progress: {
      complete: 0,
      total: 0
    },

    subMenuOn: false,
    subMenuOptions: [
      { label: 'One-Way Group Messages', mode: __C.MESSENGER.MODE_1WAY_GROUP_MESSAGE }
    ],

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},

    mode: __C.FORM.EDIT_MODE_VIEW,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['commentProps', 'updatecomment']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['getCommentTitle', 'getUpdateComment']), 
    
    __C_() { return __C },

    comments_title() { return this.getCommentTitle },
    modeView() { return this.mode == __C.FORM.EDIT_MODE_VIEW },

    senderItemBlock () { return this.commentProps.sender || this.commentProps.sender == 'ITEM_BLOCK' },
    sender2input() { 
      if(!this.commentProps.sender || !(['ITEM_BLOCK']).includes(this.commentProps.sender)) return []
      return this.commentProps.extFields ? JSON.parse(JSON.stringify(this.commentProps.extFields)) : []
    },

    manualExtData(){
      // sender2input 함께 수정하기 // jeonga
      if (this.$route.name == 'Main Plant') return true
      else return false
    },
    addable(){
      return this.account && this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.SVC_ADMIN
    },
    writable() {
      /**
       * commentProps **
       * 
       * {
       *    refCode: String,          // the code that comment(s) belong to...
       *    authority: {
       *      write: {
       *        permission: String,   // the permission code that allow to write the comment
       *        levelno: Number,      // the permission level of the permission
       *        granted: String,      // the inequality sign code for permission level, < | > | <= | >= | =
       *      }
       *    }
       * }
       */

      if(!this.commentProps.authority) return true
      if(this.commentProps.authority.write.granted == '<' && this.commentProps.authority.write.levelno < this.account.permitLevel) return true
      if(this.commentProps.authority.write.granted == '<=' && this.commentProps.authority.write.levelno <= this.account.permitLevel) return true
      if(this.commentProps.authority.write.granted == '>' && this.commentProps.authority.write.levelno > this.account.permitLevel) return true
      if(this.commentProps.authority.write.granted == '>=' && this.commentProps.authority.write.levelno >= this.account.permitLevel) return true
      if(this.commentProps.authority.write.granted == '=' && this.commentProps.authority.write.levelno == this.account.permitLevel) return true

      return false
    },

    opened: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },

    excelExpotable() { return this.commentProps.excel && this.commentProps.excel.exportable },
    tableIdx() { return this.commentProps.excel.tableIdx }
  },
  watch: {
    opened(val) {
      if(val) this.onOpen()
      else this.onClose()
    }
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')

    this.communicationService = new CommunicationService()
    this.fileService = new FileService()
  },
  mounted() {
    if(this.opened) this.onOpen()
    else this.onClose()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setUpdateComment',
      'setCallbackData'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'commentOnChanged'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'GET_EXCEL',
    ]),
    onOpen() {
      this.getComments()

      setTimeout(() => { 
        this.classWrapper = 'opened' 
        d3.select(`#${this.localId}`).select('.content').transition().delay(300).duration(200).style('opacity', 1)
      }, 500)
    },
    onClose() {
      d3.select(`#${this.localId}`).select('.content').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
      let flag = this.updatecomment ? false : true
      this.setUpdateComment(flag)
      //this.mode = __C.FORM.EDIT_MODE_EDIT
      this.mode = __C.FORM.EDIT_MODE_VIEW
    },


    onCancel(data, comment) {
      let index_ = this.comments.findIndex(c_ => c_.editor)
      if(index_ < 0) return

      let comments_ = [ ...this.comments ]
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        comments_[index_].editor = false
        comments_[index_].mode = __C.FORM.EDIT_MODE_VIEW

        if (data != undefined)  {
          comments_[index_].comment = data.comment
          this.comments = comments_
    
          let data_ = {
            parentId: comment.parentId,
            refCode : this.commentProps.refCode.toUpperCase(),
            userId  : this.account.id,
            comment : data.comment,
            ext1    : comment.ext1,
            ext2    : comment.ext2,
            ext3    : comment.ext3,
            ext4    : comment.ext4,
            ext5    : comment.ext5,
            files   : data.files.map(f => ({
              name      : f.name,
              mimeType  : f.mimeType,
              fileType  : f.fileType,
              ext       : f.ext,
              dataUrl   : f.dataUrl,
              size      : f.size,
              dimention : f.dimention,
            }))
          }

          let params = new FormData()
          params.append("idx", comments_[index_].idx)
          params.append("data", JSON.stringify(data_))
          
          data.files.forEach(f => { params.append("files", f) })
          
          let config = {
            onUploadProgress: e => {
              this.progress = {
                complete: e.loaded,
                total: e.total
              }
            }
          }

          this.communicationService.editComment(params, config).then(() => {
            this.progress = { complete: 0, total: 0 }
            this.getComments()
            this.mode = __C.FORM.EDIT_MODE_VIEW
            let flag = this.updatecomment ? false : true
            this.setUpdateComment(flag)
            // this.loading = false
            
            setTimeout(() => {
              this.setCallbackData({
                from: 'Comment Service',
                refCode: this.commentProps.refCode,
                count: this.comments.length,
                comments: JSON.parse(JSON.stringify(this.comments))
              })
              
            }, 500)
          })
        }else {
          this.getComments()
          this.mode = __C.FORM.EDIT_MODE_VIEW
        }

      } else {
        comments_[index_] = null
        this.comments = comments_.filter(c_ => !!c_)
      }

      this.mode = __C.FORM.EDIT_MODE_VIEW

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
    },
    // onDownload(fileId, name, type) {
    onDownload(fileId) {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${fileId}?${params.toString()}`

      // setTimeout(() => {
      //   iframe.parentNode.removeChild(iframe)
      // }, 10000);

      // let config = {
      //   responseType: 'blob',
      //   onDownloadProgress: e => {
      //     console.log(e.loaded, e.total)
      //   }
      // }
      // this.fileService.download(fileId, config).then(file => {
      //   let blob_ = new Blob([file], { type: type})
      //   let len_ = blob_.length
      //   let buffer_ = new ArrayBuffer(len_)
      //   let view_ = new Uint8Array(buffer_)

      //   for (var i = 0; i < len_; i++) {
      //     view_[i] = blob_.charCodeAt(i);
      //   }

      //   let url = URL.createObjectURL(blob_);
      //   let link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', name)
      //   document.body.appendChild(link)
      //   link.click()
      // })
    },
    onDelete(comment) {
      this.yes = () => {
        this.msgOpen = false
        this.communicationService.delComment(comment.idx).then(() => {
          let index_ = this.comments.findIndex(c_ => c_.idx == comment.idx)
          let comments_ = [ ...this.comments ]
          comments_[index_] = null
          this.comments = comments_.filter(c_ => !!c_)
          this.commentOnChanged()
          this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
          setTimeout(() => {
            this.setCallbackData({
            from: 'Comment Service',
            refCode: this.commentProps.refCode,
            count: this.comments.length,
            comments: JSON.parse(JSON.stringify(this.comments))
          })
          }, 500)
        })
      let flag = this.updatecomment ? false : true
      this.setUpdateComment(flag)
        this.yes = () => {}
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"

      if(comment.files.length > 0) {
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.message = `
          <span style="
            font-style: italic; 
            font-weight: 300; 
            font-size: 12px; 
            color: #f75c02
          ">
            Attention: All of its attachments will be deleted.
          </span><br /><br />
          Do you want to delete this comment?
        `
      } else this.msgInfo.message = "Do you want to delete this comment?"

      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true

      // this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
    },
    onEdit(idx) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return

      let comments_ = [ ...this.comments ]
      let index_ = this.comments.findIndex(c_ => c_.idx == idx)
      comments_[index_].editor = true
      comments_[index_].mode = __C.FORM.EDIT_MODE_EDIT

      this.comments = comments_
      this.mode = __C.FORM.EDIT_MODE_EDIT

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
      setTimeout(() => {
        this.setCallbackData({
        from: 'Comment Service',
        refCode: this.commentProps.refCode,
        count: this.comments.length,
        comments: JSON.parse(JSON.stringify(this.comments))
      })
      }, 500)
    },
    onSave(data, comment) {
      // this.loading = true
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        this.onCancel(data, comment)
        return
      }

      let data_ = {
        parentId: comment.parentId,
        refCode : this.commentProps.refCode.toUpperCase(),
        userId  : this.account.id,
        comment : data.comment,
        ext1    : comment.ext1,
        ext2    : comment.ext2,
        ext3    : comment.ext3,
        ext4    : comment.ext4,
        ext5    : comment.ext5,
        files   : data.files.map(f => ({
          name      : f.name,
          mimeType  : f.mimeType,
          fileType  : f.fileType,
          ext       : f.ext,
          dataUrl   : f.dataUrl,
          size      : f.size,
          dimention : f.dimention,
        }))
      }

      let params = new FormData()
      params.append("data", JSON.stringify(data_))
      
      data.files.forEach(f => { params.append("files", f) })

      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }
      this.communicationService.putComment(params, config).then(() => {
        this.progress = { complete: 0, total: 0 }
        this.getComments()
        this.mode = __C.FORM.EDIT_MODE_VIEW
        let flag = this.updatecomment ? false : true
        this.setUpdateComment(flag)
        // this.loading = false
        
        this.$emit('updateComment', {code: this.commentProps.refCode, comments: data.comment})

        setTimeout(() => {
          this.setCallbackData({
            from: 'Comment Service',
            refCode: this.commentProps.refCode,
            count: this.comments.length,
            comments: JSON.parse(JSON.stringify(this.comments))
          })
        }, 500)
      })

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: data.comment})
    },
    onThumbnail(file, dataUrl) {
      this.$emit('thumbnail', { file, dataUrl })
    },
    onWrite(parentId) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return
      let comment_ = {
        editor: true,
        depth: parentId ? 1 : 0,
        parentId: Number(parentId),
        userName: this.account.userName,
        position: this.account.position,
        dept: this.account.department,
      }
      if(parentId) {
        let comments_ = [ ...this.comments ]
        let index_ = this.comments.findIndex(c_ => c_.idx == parentId)
        let subComments_ = this.comments.filter(c_ => c_.idx == parentId || c_.parentId == parentId)

        subComments_.push(comment_)
        comments_.splice(index_, subComments_.length-1, ...subComments_)
        comments_ = comments_.filter(c_ => !!c_)

        this.comments = comments_
        
      } else {
        this.comments.push(comment_)
      }
      
      this.mode = __C.FORM.EDIT_MODE_ADD
    },

    async excelDownload() {
      if(this.loading) return
      
      this.loading = true

      let params = new URLSearchParams()
      params.append('idx', this.tableIdx)
      params.append('fValues', JSON.stringify({ REF_CODE: this.commentProps.refCode }))
      params.append('iFilter',  '')
      params.append('easyFormedHeaders', '')
      params.append('pagination', JSON.stringify({page:1, rowsPerPage:40, reset:1}))
      params.append('requestType', 'full')
      params.append('token', this.account.token)

      const send_Data = {
        url_ : `${Url.fetch.excel.get}?${params.toString()}`,
        title: this.comments_title
      }

      await this.GET_EXCEL(send_Data)
      
      this.loading = false
    },

    fileDocs(files) {
      return files.filter(f_ => !['image', 'video'].includes(f_.fileType))
    },
    fileImages(files) {
      return files.filter(f_ => ['image', 'video'].includes(f_.fileType))
    },
    getComments() {
      this.communicationService.getComments(this.commentProps.refCode.toUpperCase()).then(res => {
        this.comments = res
      })
    },
    removable(comment) {
      if(comment.depth == 0 && this.comments.findIndex(c_ => c_.parentId == comment.idx) >= 0) return false
      if(this.account && this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.SVC_ADMIN) return true
      if(comment.userId != this.account.id) return false
      
      return true
    },
    editable(comment) {
      if(this.account && this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.SVC_ADMIN) return true
      if(comment.userId != this.account.id) return false
      //if(comment.depth == 0 && this.comments.findIndex(c_ => c_.parentId == comment.idx) >= 0) return false
      return false
    },
  }
}
</script>

<style lang="stylus" scoped>
.j_comm_comments__content_wrapper {
  position: relative;
  display: flex;
  right: 7rem;
  height: 100vh;
  background-color: #f5f5f5;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.16);
  transition: width .45s;

  &.opened {
    width: 140rem;
  }
  &.closed {
    width: 0;
  }

  .lnb {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    width: .5rem;
    background-color: #eee;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    
    .action_button_wrapper {
      position: absolute;
      top: .6rem;
      right: 4rem;
      
      display: flex;
    }

    [class^=btn-] {
      text-align: center;
      color: #909090;
      font-size: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      transition: color .15s
    }
    [class^=btn-]:hover {
      color: #454545;
    }

    .btn-cancel {
      position: absolute;
      top: .6rem;
      right: .5rem;
    }
    .btn-export {
      opacity: .55

      img {
        margin-top: .3rem;
      }
    }
    .btn-export:hover {
      opacity: .75
    }

    .sub-menu---activator {
      position: absolute;
      top: .6rem;
      right: .5rem;
      display: flex;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      color: #909090;
      font-size: 1.8rem;
      border-radius: 50%;
    }
    .sub-menu---activator:hover {
      color: #454545;
    }
    .sub-menu {
      position: absolute;
      top: 2.8rem;
      right: 1.5rem;
      width: 18rem;
      background-color: #fafafa;
      border-radius: .3rem;
      box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.16);
      z-index: 1;

      .sub-menu-item {
        display: flex;
        align-items: center;
        padding: 0 .7rem;
        height: 2.6rem;
        color: #575757;
        border-radius: .3rem;
        transition: color background-color .15s;

        .sub-menu-label {
          margin-left: .5rem;
        }
      }
      .sub-menu-item:hover {
        color: #000;
        background-color: #eee;
        cursor: pointer;
      }
    }

    .tab-header {
      display: flex;
      align-items: center;
      height: 4rem;
      background-color: #eee;

      .tab-title {
        font-size: 1.2rem;
        font-weight: 900;
        margin-left: 1rem;
      }

      .tab-sub-title {
        font-weight: 500;
        color: #aeaeae;
        margin-left: 1rem;
      }
    }

    .button_replay_wrapper {
      position: absolute;
      top: -.5rem;
      right: 17.5rem;
      display: flex;
      justify-content: flex-end;
    }

    .comment_wrapper {
      position: absolute;
      top: 4rem;
      bottom: 0;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .editor {
        position: relative;
        margin-top: .5rem;

        for i in (0 .. 1) {
          $default_width = 133rem;
          $depth_width = 6.5rem;

          &.depth_{i} {
            width: $default_width - ($depth_width * i);
          }
        }
      }
    }
    .comment_item {
      display: flex;
      padding: 1.5rem 2rem;
      transition: background-color .15s;

      for i in (0 .. 1) {
        $depth_width = 6.5rem;   // property button size :second tabs

        &.depth_{i} {
          margin-left: $depth_width * i;
        }
      }

      .v-avatar {
        border: solid 2px #fdfdfd;
      }
      .member-info {
        position: relative;
        margin-left: 2rem;
        width: 100%;

        .member-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .name {
            text-align: left;
            font-size: 1.2rem;
            font-weight: 500;
            color: #000;
          }
          .updated {
            display: flex;

            span {
              display: inline-block;
            }

            .date {
              margin-right: 2rem;
              padding-bottom: 3px;
            }
            .time {
              margin-left: 3px;
            }
          }
        }
        .comment {
          color: #333;
          font-size: 1.1rem;
          margin-top: 3px;
          margin-bottom: .5rem;

          p {
            margin: 0;
            word-break: break-all;
          }
        }
        .attachement {
          .file_names {
            display: flex;
            align-items: center;
            color: #000;

            .mdi-attachment {
              font-size: 1.6rem;
            }
            .names {
              color: #333;
              margin: 0 .5rem;
              padding-bottom: .5rem;
            }
            .names:hover {
              color: #1976d2
            }
          }
          .thumbs {
            display: flex;

            .thumb {
              display: flex;
              flex-direction: column;
              margin-right: 3px;

              a {
                border: solid 1px rgba(0, 0, 0, 0)

                img {
                  margin-bottom: -.4rem;
                }
              }
              a:hover {
                border: solid 1px #ffca28;
              }
            }

            // .thumb > * {
            //   margin-left: 3px;
            //   margin-right: 3px;
            // }
          }
        }
      }

      .member-position {
        color: #aeaeae;
        text-align: left;
      }
    }
  }
}
</style>
